@tailwind base
@tailwind components
@tailwind utilities

/* styling vars */

$primary-font-stack: helvetica
$secondary-font-stack: serif

.primary-font-stack
	font-family: $primary-font-stack

$primary-font-color: white

.primary-font-color
	color: $primary-font-color

$secondary-color: red
$tertiary-color: DarkRed
$bg-color: black

.bg-color
	background-color: $bg-color

.trolley
	background-image: url("./res/static/trolley.png")
	background-repeat: repeat
	height: 100vh
.vellum
	background-image: url("./res/static/vellum.jpg")
	background-repeat: repeat	

// Dimensions
$dot-size: 1px
$dot-space: 22px

$header-padding-y: 2.2rem
$header-padding-x: 1rem
$grid-line-size: 0.05rem
$content-margin: 0.1rem
$hover-transition-time: 0.25s

/* font face declarations */
@font-face 
	font-family: 'Favorit Extended'
	font-style: normal
	font-weight: normal
	src: local('Favorit Extended'), url('./res/fonts/Favorit-RegularExtended.otf')

@font-face 
	font-family: 'Acumin Pro'
	font-style: normal
	font-weight: normal
	src: local('Acumin Pro'), url('./res/fonts/Acumin-RPro.woff') format('woff')

@font-face 
	font-family: 'Acumin Pro Italic'
	font-style: normal
	font-weight: normal
	src: local('Acumin Pro Italic'), url('./res/fonts/Acumin-ItPro.woff') format('woff')

@font-face 
	font-family: 'Acumin Pro Bold'
	font-style: normal
	font-weight: normal
	src: local('Acumin Pro Bold'), url('./res/fonts/Acumin-BdPro.woff') format('woff')
       
@font-face 
	font-family: 'Acumin Pro Bold Italic'
	font-style: normal
	font-weight: normal
	src: local('Acumin Pro Bold Italic'), url('./res/fonts/Acumin-BdItPro.woff') format('woff')

html, body
	margin: 0
	min-height: 100%
	background-color: black
	font-size: 16px
	overflow-x: hidden
	

header
	display: flex

	nav
		display: flex
		flex-direction: column

main
	display: flex

	.stage
		display: flex
		justify-content: center
		width: 50%

		#blob
			transform: translate(0,0)

	.menu
		width: 50%


.textContainer
	// Style
	background-color: black
	color: white
	font-family: $primary-font-stack

	// Display
	display: flex
	align-items: flex-start
	
.flex-grid
	display: flex
	flex-wrap: wrap
	/*justify-content: space-between*/

	.item	
		flex: 0 32%
		height: 100px
		margin-bottom: 8rem
	
	img
		height: 12rem !important

	svg
		width: 125% !important
		height: auto !important


.item
	display: flex
	
	justify-content: center
	align-items: center

	//cursor: pointer
	position: relative // for blobs

	img
		height: 30rem
	svg
		height: 40rem
		position: absolute
		

.dropdown
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center

	.dropdown-btn
		display: flex
		justify-content: center
		align-items: center
	.dropdown-content
		/*display: none*/
		background-color: none
		visibility: hidden

.draggable
	cursor: none
.draggable:hover
	cursor: none

.block
	margin-top: 2rem
	margin-bottom: 2rem
	// padding-left: 6rem
	// padding-right: 6rem 

.textColumn
	div:first-child
		padding-right: 2rem
	div:last-child
		padding-left: 2rem

.dropdown:hover .dropdown-content
	visibility: visible 

.dropdown:hover .dropdown-btn
	img
		transform: rotate(90deg)

.dropdown-btn:hover
	img
		transform: rotate(90deg)

#expansionContainer
	height: 800px

.btn-secondary
	background-color: $secondary-color
.btn-secondary:hover
	//cursor: pointer
	background-color: $tertiary-color

.blot-portrait
	position: relative
	overflow: hidden
	border-radius: 50%
	margin: 0px auto
	//background-color: white

	img
		width: 100%
		height: auto

.blot-portrait:hover, .blot-portrait:active, .blot-portrait.active, svg:hover
	-webkit-filter: grayscale(100%)
	filter: grayscale(100%)
	

.blot-portrait:hover
	//background-color: $secondary-color
	//transition: background-color 0.5s ease

.blot-portrait:active, .blot-portrait.active
	//background-color: $tertiary-color
.contentWrapper
	svg
		position: absolute
		width: 100%
		top: 0%
		transform: translate(-50%,-25%)
.contentBody
	//background-color: black
	color: black
	text-align: center
	padding: 1rem
	font-family: $primary-font-stack

	top: 10%
	
	p
		margin-top: 0


.puzzle-container
	display: flex
	flex-direction: row
	flex-wrap: wrap

	width: 33.5%
	
	h1
		z-index: 0
		align-self: center
		position: absolute
		left: 50%
		transform: translateX(-50%)
		width: 100%

	.background
		width: 50vw
		z-index: 2
		position: relative

	.puzzleBackground
		position: absolute
		width: 70%
		height: 70%
		left: 50%
		top: 50%
		transform: translate(-50%,-50%)
		z-index: 1
		
		
		

	justify-content: center

	.link
		position: absolute
		z-index: 2
		height: fit-content

		p
			font-family: $primary-font-stack
			color: white

	.l1
		top: 37%
		left: 35%
	.l2
		top: 35%
		left: 61%
	.l3
		top: 56%
		left: 30%
	.l4
		top: 56%
		left: 56%
	

	.piece
		height: fit-content
		position: absolute

	
		z-index: 3

	.p1
		width: 30% //450px
		img
			width: 100%
			
			height: auto

		top: 28.2%
		left: 23.05%

	.p2
		width: 25.8% //390px
		img
			width: 100%
			height: auto

		top: 28.6%
		left: 50.9%

	.p3
		width: 29.5% //450px
		img
			width: 100%
			height: auto

		top: 45.1%
		left: 17.9%

	.p4
		width: 37% // 550px
		img
			width: 100%
			height: auto
			
		top: 47.3%
		left: 41.65%

.hiddenClue
	color: white
	position: absolute
	z-index: 0
	top: 6%
	width: 50%
	left: -5%

.backgroundTitle
	font-size: 20rem
a
	color: $primary-font-color
	text-decoration: none
	cursor: none

a:visited
	color: $primary-font-color
	text-decoration: none

a:hover
	color: $secondary-color
	text-decoration: none

a:active
	color: $tertiary-color
	text-decoration: none


.bc-secondary
	background-color: $secondary-color


$primary-grid-border-stack: 1px solid rgba(255,255,255,0.25)

.cursor-none
	cursor: none
.bg-noisy
	background-image: url("./res/static/noiseGrey2.png")


line.point
	stroke: white
	stroke-width: 1
	vector-effect: non-scaling-stroke

.grid-top
	border-bottom: $primary-grid-border-stack
.central-grid
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	position: absolute
	top: 0
	left: 0	
	
	width: 100%
	height: 100%
	z-index: 30
	pointer-events: none

	.cell
		width: 60%
		flex: 0 0 33%
		border-left: $primary-grid-border-stack
		border-right: $primary-grid-border-stack
	.col:nth-child(3n+2)
		flex: 0 0 60%

	.col:nth-child(3n), .col:nth-child(3n+2) 
		border-left: $primary-grid-border-stack

	.col:nth-child(n+4):nth-child(-n+6)
		border-top: $primary-grid-border-stack
		border-bottom: $primary-grid-border-stack

html, .noScrollY
	-ms-overflow-style: none
	scrollbar-width: none

html::-webkit-scrollbar, .noScrollY::-webkit-scrollbar 
	display: none
	background: transparent

.fadeOut
	opacity: 0
	transition: all 2s ease-in-out
.fadeOutFast
	opacity: 0
	transition: all 0.5s ease-in-out
.fadeIn
	opacity: 1
	transition: all 4s ease-in-out


@media screen and (min-width: 992px) 
	html
		font-size: 12px
 
@media screen and ( min-width: 1200px )
	html
		font-size: 16px

//@media screen and ( min-width: 1600px )
//	html
//		font-size: 16px	
		 
/* Main CSS */
#gallery
	/* Prevent vertical gaps */
	line-height: 0

	-webkit-column-count: 5
	-webkit-column-gap:   0px
	-moz-column-count:    5
	-moz-column-gap:      0px
	column-count:         3
	column-gap:           0px


#gallery 
	div
		img
			/* Just in case there are inline attributes */
			width: 100% !important
			height: auto !important



$borderFadeAlpha: 0.3

.borderIn
	border: 2px solid rgba(255,255,255,1)
.borderOut
	border: 2px solid rgba(255,255,255,$borderFadeAlpha)

.borderFadeIn
	transition: border-color 3s linear

.borderFadeOut
	transition: border-color 1.5s linear