// Colors
$primary: white;
$primary-ultralight: mix(white, $primary, 80%);
$primary-light: mix(white, $primary, 25%);
$primary-dark: mix(black, $primary, 25%);
$primary-red: mix(red, $primary, 25%);
$primary-ultradark: mix(black, $primary, 80%);
$black: #333333;
$white: #eeeeee;
$trans: 500ms;

.cursor-dot,
.cursor-dot-outline {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease-in-out,
                transform 0.3s ease-in-out,
                background-color $trans linear;
}

.cursor-dot {
    $size: 8px;
    width: $size;
    height: $size;
    
}

.dotNormal {
    background-color: $primary;
}

.dotLink {
    background-color: rgba(0,0,0,0);
}

.cursor-dot-outline {
    $size: 40px;
    width: $size;
    height: $size;
    
}

.normalColor{
    background-color: rgba($primary-light, 0.2);
    
}
.dragColor {
    background-color: rgba(DarkRed, 0.7) !important;
    //animation: dragColorChange 5s infinite;

}

.linkColor{
    background-color: rgba(DarkRed, 0.3) !important;
}

@keyframes dragColorChange {
    from {background-color: rgba($primary-light, 0.2);}
    to {background-color:  rgba(DarkRed, 0.7);}
  }